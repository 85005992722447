import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import axios from "axios";
import toast from "react-hot-toast";

const CompanySettings = () => {
  const [formData, setFormData] = useState({
    name: "",
    website: "",
    address: "",
    city: "",
    state: "",
    country: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCompanyPresent, setIsCompanyPresent] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const authData = JSON.parse(localStorage.getItem("auth"));

    if (authData) {
      const { user } = authData;
      const { company, is_verified } = user || {};

      if (company) {
        setFormData({
          name: company.name || "",
          website: company.website || "",
          address: company.address || "",
          city: company.city || "",
          state: company.state || "",
          country: company.country || "",
        });
        setIsCompanyPresent(true);
      }
    }

    setIsLoading(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        console.error("Access token not found in localStorage.");
        setIsSubmitting(false);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/user/update/`,
        {
          company: {
            name: formData.name,
            website: formData.website,
            address: formData.address,
            city: formData.city,
            state: formData.state,
            country: formData.country,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(
          isCompanyPresent
            ? "Company data updated successfully"
            : "Company data added successfully"
        );

        const updatedCompanyData = {
          name: formData.name,
          website: formData.website,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          country: formData.country,
        };

        const authData = JSON.parse(localStorage.getItem("auth"));
        if (authData && authData.user) {
          authData.user.company = updatedCompanyData;
          localStorage.setItem("auth", JSON.stringify(authData));
          console.log("Local storage updated with new company data.");
        }
      } else {
        console.error("Failed to update company data");
      }
    } catch (error) {
      console.error("Error updating company data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-slate-100 raleway-regular ">
      <Header />
      <div className="min-h-[90vh] bg-slate-100 flex justify-center items-center">
        <div className="bg-white w-full md:w-[50vw] flex flex-col justify-center items-center p-4 md:p-8 rounded-md">
          <div className="w-full bg-white p-6 rounded-lg shadow">
            {isLoading || isSubmitting ? (
              <div className="text-center text-gray-600">
                <Spinner />
              </div>
            ) : (
              <>
                {/* <div className="flex justify-between flex-wrap mb-6">
                  <div className="flex items-center border-b-2 pb-2">
                    <h2 className="text-xl font-semibold mr-2">
                      Email Verification
                    </h2>
                    {emailVerification ? (
                      <FaCheckCircle className="text-green-500" size={24} />
                    ) : (
                      <FaTimesCircle className="text-red-500" size={24} />
                    )}
                  </div>
                </div> */}
                <div className="flex justify-between flex-wrap mb-6">
                  <div className="flex items-center border-b-2 pb-2">
                    <h2 className="text-xl font-semibold mr-2">
                      {isCompanyPresent
                        ? "Update Company Information"
                        : "Add Company Information"}
                    </h2>
                  </div>
                </div>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                      <label htmlFor="companyName" className="mb-1">
                        Company Name
                      </label>
                      <input
                        type="text"
                        id="companyName"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                        className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="website" className="mb-1">
                        Website
                      </label>
                      <input
                        type="text"
                        id="website"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                        className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                      <label htmlFor="streetAddress" className="mb-1">
                        Street Address
                      </label>
                      <input
                        type="text"
                        id="streetAddress"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="city" className="mb-1">
                        City
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                      <label htmlFor="state" className="mb-1">
                        State
                      </label>
                      <input
                        type="text"
                        id="state"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="country" className="mb-1">
                        Country
                      </label>
                      <input
                        type="text"
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="flex justify-center mt-4">
                    <button
                      type="submit"
                      className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                    >
                      {isCompanyPresent
                        ? "Update Company Information"
                        : "Add Company Information"}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySettings;
