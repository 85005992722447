import React, { useState, useEffect, useContext } from "react";
import { FaEye, FaFilePdf } from "react-icons/fa";
import { FaFile } from "react-icons/fa";
import { RxDownload } from "react-icons/rx";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiFolder } from "react-icons/fi";
import DownloadModal from "./DownloadModal";
import DeleteModal from "./DeleteModal";
import PreviewModal from "./PreviewModal";
import { AuthContext } from "../AuthContext";
import { IoMdAdd } from "react-icons/io";
import NewFolderModal from "./NewFolderModal";
import DeleteFolderModal from "./DeleteFolderModal";
import { BeatLoader } from "react-spinners";
import BulkPreviewModal from "./BulkPreviewModal";
import BulkDownloadModal from "./BulkDownloadModal";
import toast from "react-hot-toast";

const DataTable = () => {
  const [currentData, setCurrentData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([
    { name: "All Documents", id: null, data: [] },
  ]);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [downloadModalContent, setDownloadModalContent] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalContent, setPreviewModalContent] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [parentFolderId, setParentFolderId] = useState(null);

  const [selectedAllDocuments, setSelectedAllDocuments] = useState(false);
  const [bulkReviewContent, setBulkReviewContent] = useState([]);
  const [isBulkPreviewModalOpen, setIsBulkPreviewModalOpen] = useState(false);
  const [bulkDownloadContent, setBulkDownloadContent] = useState([]);
  const [isBulkDownloadModalOpen, setIsBulkDownloadModalOpen] = useState(false);

  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [folderId, setFolderId] = useState(null);

  const { fetchData, tableData, tableLoader, userData } =
    useContext(AuthContext);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (tableData?.length > 0) {
      // Get the last breadcrumb entry (current folder)
      const currentFolder = breadcrumb[breadcrumb.length - 1];

      // Fetch the folders and files for the current folder
      const updatedFolders = tableData.filter(
        (item) => item.parent === currentFolder.id
      );
      const updatedFiles = tableData.filter(
        (item) => item.folder === currentFolder.id
      );

      // Set currentData to reflect the updated folder
      setCurrentData([...updatedFolders, ...updatedFiles]);

      // Update breadcrumb data for the current folder
      setBreadcrumb((prevBreadcrumb) =>
        prevBreadcrumb.map((crumb, index) =>
          index === breadcrumb.length - 1
            ? { ...crumb, data: [...updatedFolders, ...updatedFiles] }
            : crumb
        )
      );
    } else {
      setCurrentData([]);
    }
  }, [tableData]);

  // console.log(tableData);

  useEffect(() => {
    if (selectedAllDocuments) {
      setSelectedRows(currentData.map((item) => item.id));
    } else {
      setSelectedRows([]);
    }
  }, [selectedAllDocuments, currentData]);

  const handleRowSelection = (identifier) => {
    let updatedSelectedRows;

    if (selectedRows.includes(identifier)) {
      updatedSelectedRows = selectedRows.filter((item) => item !== identifier);

      const selectedFolder = currentData.find(
        (item) => item.id === identifier && item.subfolders !== undefined
      );
      if (selectedFolder) {
        const folderFileIds = selectedFolder.files.map((file) => file.id);
        updatedSelectedRows = updatedSelectedRows.filter(
          (id) => !folderFileIds.includes(id)
        );
      }
    } else {
      updatedSelectedRows = [...selectedRows, identifier];

      const selectedFolder = currentData.find(
        (item) => item.id === identifier && item.subfolders !== undefined
      );
      if (selectedFolder) {
        const folderFileIds = selectedFolder.files.map((file) => file.id);
        updatedSelectedRows = [...updatedSelectedRows, ...folderFileIds];
      }
    }

    setSelectedRows(updatedSelectedRows);
  };

  const handleFolderClick = (folder) => {
    const combinedData = [...folder.subfolders, ...folder.files];
    setBreadcrumb((prevBreadcrumb) => [
      ...prevBreadcrumb,
      { name: folder.name, id: folder.id, data: combinedData },
    ]);
    setCurrentData(combinedData);
  };

  const handleBreadcrumbClick = (index) => {
    const newBreadcrumb = breadcrumb.slice(0, index + 1);
    const folder = newBreadcrumb[index];
    setCurrentData(folder.data);
    setBreadcrumb(newBreadcrumb);
  };

  const handleDownloadClick = (item) => {
    setDownloadModalContent(item);
    setIsDownloadModalOpen(true);
  };

  const getFileFormat = (item) => {
    const fileExtension = item.file?.split(".").pop().toLowerCase();
    const fileType = ["pdf", "jpg", "jpeg", "png"].includes(fileExtension)
      ? fileExtension
      : "unknown";

    return fileType;
  };

  const handlePreviewClick = (item) => {
    const fileType = getFileFormat(item);

    setPreviewModalContent({
      fileLink: process.env.REACT_APP_BACKEND_URL + item.file,
      format: fileType,
      name: item?.file_name ? item?.file_name : item.file?.split("/").pop(),
      id: item?.id,
      information: item?.information,
      status: item?.status,
    });

    setIsPreviewModalOpen(true);
  };

  const handleDeleteClick = (item) => {
    setFileId(item.id);
    setIsDeleteModalOpen(true);
  };

  const handleFolderDeleteClick = (folder) => {
    setFolderId(folder.id);
    setIsDeleteFolderModalOpen(true);
  };

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
    setDownloadModalContent(null);
  };

  const closePreviewModal = () => {
    setIsPreviewModalOpen(false);
    setPreviewModalContent(null);
    // fetchData();
  };

  useEffect(() => {
    const selectedFiles = [];

    selectedRows.forEach((identifier) => {
      const item = currentData.find((item) => item.id === identifier);

      if (item) {
        if (item.subfolders !== undefined) {
          selectedFiles.push(...item.files);
        } else {
          selectedFiles.push(item);
        }
      }
    });

    setBulkReviewContent(selectedFiles);
    setBulkDownloadContent(selectedFiles);
  }, [selectedRows, currentData, setBulkReviewContent, setBulkDownloadContent]);

  const handleAddNewFolder = () => {
    const lastBreadcrumb = breadcrumb[breadcrumb.length - 1];
    const parentId = lastBreadcrumb.id;

    setParentFolderId(parentId);
    setIsNewFolderModalOpen(true);
  };

  const handleBulkReview = () => {
    if (bulkReviewContent.length === 0) {
      toast("Please choose the files", {
        duration: 2000,
      });
    } else {
      // Store bulk review content in localStorage before opening modal
      localStorage.setItem(
        "bulkReviewContent",
        JSON.stringify(bulkReviewContent)
      );
      setIsBulkPreviewModalOpen(true);
    }
  };

  const handleBulkDownload = () => {
    if (bulkDownloadContent.length === 0) {
      toast("Please choose the files", {
        duration: 2000,
      });
    } else if (
      bulkDownloadContent.some((file) => file.status !== "EXTRACTED")
    ) {
      toast("Please choose only extracted files to download", {
        duration: 2000,
      });
    } else {
      setIsBulkDownloadModalOpen(true);
    }
  };

  const closeBulkPreviewModal = () => {
    // Remove bulkReviewContent from localStorage when modal closes
    localStorage.removeItem("bulkReviewContent");
    setIsBulkPreviewModalOpen(false);
    // fetchData();
  };

  const formatName = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  return (
    <div className="overflow-x-auto rounded-md shadow-md mt-4 raleway-regular">
      <div className="flex justify-between px-4 mb-2">
        <div className="flex">
          <label
            className="flex items-center space-x-1 whitespace-nowrap"
            title="Select All Documents"
          >
            <input
              type="checkbox"
              className="form-checkbox"
              value={selectedAllDocuments}
              onChange={() => setSelectedAllDocuments(!selectedAllDocuments)}
            />
            {/* <span className="text-black">All Documents</span> */}
          </label>
          <div className="p-4">
            {breadcrumb.map((crumb, index) => (
              <span key={index}>
                <button
                  onClick={() => handleBreadcrumbClick(index)}
                  className="text-blue-600 hover:underline"
                >
                  {crumb.name}
                </button>
                {index < breadcrumb.length - 1 && " > "}
              </span>
            ))}
          </div>
          <div className="flex items-center space-x-2">
            <button
              className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
              onClick={handleBulkReview}
            >
              Bulk Review
            </button>
            <button
              className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
              onClick={handleBulkDownload}
            >
              Bulk Download
            </button>
          </div>
        </div>
        <div className="flex justify-center items-center space-x-2 mx-2">
          <span className="text-black font-medium">New Folder</span>
          <button
            className="bg-white text-sky-500 px-2 py-2 border border-sky-400 rounded-full focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
            onClick={handleAddNewFolder}
          >
            <IoMdAdd />
          </button>
        </div>
      </div>

      <div className="max-h-[62vh] overflow-y-auto">
        {tableLoader ? (
          <div className="flex justify-center items-center p-4">
            <BeatLoader />
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 z-0">
              <tr>
                <th className="px-6 py-3"></th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Format
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Size
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Owner
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentData.length > 0 ? (
                currentData?.map((item) => (
                  <tr
                    key={item.id || item.name}
                    className={`${
                      selectedRows.includes(item.id) ? "bg-blue-50" : ""
                    }`}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={() => handleRowSelection(item.id)}
                      />
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap cursor-pointer"
                      onClick={() =>
                        item.subfolders
                          ? handleFolderClick(item)
                          : handlePreviewClick(item)
                      }
                    >
                      <span className="flex items-center">
                        {item.subfolders ? (
                          <FiFolder className="mr-2" size={25} />
                        ) : ["jpg", "jpeg", "png"].includes(
                            getFileFormat(item)
                          ) ? (
                          <img
                            src={process.env.REACT_APP_BACKEND_URL + item?.file}
                            alt=""
                            className="object-contain w-7 h-7 rounded-md border-indigo-950 border-2 mr-2"
                          />
                        ) : (
                          <FaFilePdf className=" w-7 h-7 rounded-md border-indigo-950 border-2 mr-2 p-1" />
                        )}

                        {item.name
                          ? item.name
                          : item?.file_name
                          ? item?.file_name.split(".")[0]
                          : item.file.split("/").pop().split(".")[0]}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.subfolders
                        ? "-"
                        : item.file.split("/").pop().split(".").pop()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.subfolders ? "-" : item.file_size}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap w-full">
                      <div className="flex  items-center gap-2 bg-">
                        <div className="bg-slate-300 rounded-full w-8 h-8 flex justify-center items-center">
                          {item?.owner_details
                            ? item?.owner_details?.first_name.slice(0, 1) +
                              item?.owner_details?.last_name.slice(0, 1)
                            : "-"}
                        </div>
                        <div className="flex flex-col bg-lime-2 ">
                          <div className="">
                            {item?.owner_details
                              ? item?.owner_details?.first_name +
                                " " +
                                item?.owner_details?.last_name
                              : "-"}
                          </div>
                          <div className="flex gap-2 w-3 h-5">
                            <span>
                              {new Date(item?.created_at)
                                .toString()
                                .slice(4, 16)}
                            </span>
                            <span>
                              {new Date(item?.created_at)
                                .toString()
                                .slice(16, 21)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.subfolders ? "Folder" : formatName(item.file_type)}
                    </td>
                    <td
                      className={`px-6 py-4 whitespace-nowrap font-semibold 
                      ${item?.status === "NOT_EXTRACTED" ? "text-sky-400" : ""} 
                      ${item?.status === "FAILED" ? "text-red-500" : ""} 
                      ${item?.status === "EXTRACTED" ? "text-[#191D55]" : ""} 
                      ${item?.status === "EXTRACTING" ? "text-sky-500" : ""} 
                      `}
                      title={
                        item?.status === "NOT_EXTRACTED"
                          ? "This file is in queue"
                          : item?.status === "FAILED"
                          ? "Please view the file and try extracting the data."
                          : ""
                      }
                    >
                      {item.subfolders
                        ? "-"
                        : item.status === "NOT_EXTRACTED"
                        ? "Pending"
                        : formatName(item?.status)}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-2">
                        {!item.subfolders && (
                          <>
                            <button
                              onClick={() => handlePreviewClick(item)}
                              className="bg-white text-sky-500 px-2 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                              title="View Extracted Data"
                            >
                              <FaEye />
                            </button>
                            <button
                              onClick={() => handleDownloadClick(item)}
                              className="bg-white text-green-500 px-2 py-2 border border-green-400 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 hover:bg-green-400 hover:text-white transition duration-300 ease-in-out"
                              disabled={
                                item?.status === "NOT_EXTRACTED" ||
                                item?.status === "FAILED" ||
                                item?.status === "EXTRACTING"
                              }
                              title="Download"
                            >
                              <RxDownload />
                            </button>
                          </>
                        )}
                        <button
                          onClick={() =>
                            item.subfolders
                              ? handleFolderDeleteClick(item)
                              : handleDeleteClick(item)
                          }
                          className="bg-white text-red-500 px-2 py-2 border border-red-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-400 hover:text-white transition duration-300 ease-in-out"
                          disabled={item?.status === "EXTRACTING"}
                          title="Delete"
                        >
                          <RiDeleteBinLine />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    className="text-center p-4 text-gray-500 whitespace-nowrap"
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      <NewFolderModal
        isNewFolderModalOpen={isNewFolderModalOpen}
        closeNewFolderModal={() => setIsNewFolderModalOpen(false)}
        parentFolderId={parentFolderId}
      />

      <DownloadModal
        isDownloadModalOpen={isDownloadModalOpen}
        closeDownloadModal={closeDownloadModal}
        downloadModalContent={downloadModalContent}
      />

      <PreviewModal
        isPreviewModalOpen={isPreviewModalOpen}
        closePreviewModal={closePreviewModal}
        previewModalContent={previewModalContent}
      />

      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        closeDeleteModal={() => setIsDeleteModalOpen(false)}
        fileId={fileId}
        setFileId={setFileId}
      />

      <DeleteFolderModal
        isDeleteFolderModalOpen={isDeleteFolderModalOpen}
        closeDeleteFolderModal={() => setIsDeleteFolderModalOpen(false)}
        folderId={folderId}
        setFolderId={setFolderId}
      />

      {/* BulkPreviewModal component */}
      {isBulkPreviewModalOpen && (
        <BulkPreviewModal
          bulkPreviewModalContent={JSON.parse(
            localStorage.getItem("bulkReviewContent")
          )}
          isBulkPreviewModalOpen={isBulkPreviewModalOpen}
          closeBulkPreviewModal={closeBulkPreviewModal}
        />
      )}
      {isBulkDownloadModalOpen && (
        <BulkDownloadModal
          downloadBulkModalContent={bulkDownloadContent}
          isBulkDownloadModalOpen={isBulkDownloadModalOpen}
          closeBulkDownloadModal={() => setIsBulkDownloadModalOpen(false)}
        />
      )}
    </div>
  );
};

export default DataTable;
