import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import GetAQuoteModal from "../components/GetAQuoteModal";
import { FaCheck } from "react-icons/fa";

const Plans = () => {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visiblePlans, setVisiblePlans] = useState([0, 4]);
  const [submittingPlanId, setSubmittingPlanId] = useState(null);
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchSubscriptionDetails = () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        setCancelSubscription(userData?.cancel_subscription);
        let paymentDetails = {};
        if (userData?.is_trial_period) {
        } else {
          paymentDetails["Plan Name"] = userData?.razorpay_plan_name;
        }
        setSubscriptionDetails(paymentDetails);
      } else {
        throw new Error("No user data found in local storage.");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchPlans = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/subscription/razorpay-plans/`
      );
      setPlans(response?.data?.data || []);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchPlans();
    fetchSubscriptionDetails();
  }, []);

  const handleNext = () => {
    setVisiblePlans([visiblePlans[0] + 1, visiblePlans[1] + 1]);
  };

  const handlePrev = () => {
    setVisiblePlans([visiblePlans[0] - 1, visiblePlans[1] - 1]);
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "INR":
        return "₹";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      default:
        return "";
    }
  };

  const handleSubscribe = async (plan_id) => {
    try {
      setSubmittingPlanId(plan_id);
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        console.error("Access token not found");
        setSubmittingPlanId(null);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/subscription/razorpay-subscriptions/`,
        { plan_id: plan_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.data?.short_url) {
        navigate("/");
        window.open(response.data.data.short_url, "_blank");
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      console.log("Error subscribing to the plan:", error);
      toast.error("Error subscribing, please try again");
    } finally {
      setSubmittingPlanId(null);
    }
  };

  // if (!isLoggedIn) {
  //   return <Navigate to="/login" />;
  // }

  return (
    <div className="min-h-screen bg-white raleway-regular">
      <Header />

      <div className="text-center my-8">
        <h1 className="text-4xl font-bold">Plans</h1>
      </div>

      {/* <div className="flex items-center justify-center gap-4">
        {visiblePlans[0] > 0 && (
          <button
            onClick={handlePrev}
            className="text-2xl p-2 border rounded-md hover:bg-gray-100 hidden lg:block"
          >
            &#8592;
          </button>
        )}

        <div className="flex flex-wrap flex-col items-center lg:flex-row lg:justify-center gap-8">
          {!isLoading &&
            !error &&
            plans.slice(visiblePlans[0], visiblePlans[1]).map((plan) => (
              <div
                key={plan.id}
                className="flex flex-col justify-between border border-black p-6 rounded-md min-h-72 w-72 text-center hover:shadow-lg"
              >
                <h2 className="text-5xl font-bold mb-4">
                  {getCurrencySymbol(plan.item.currency)}
                  {plan.item.amount / 100}
                </h2>
                <p className="text-gray-600 mb-4">
                  Billed {plan.interval} {plan.period}
                </p>
                <p className="text-lg mb-2 font-semibold">{plan.item.name}</p>
                <p className="text-lg mb-2">
                  {plan.item.description || "No description available"}
                </p>
                <button
                  className="bg-sky-400 text-white px-6 py-2 rounded-md hover:bg-sky-500"
                  onClick={() => handleSubscribe(plan.id)}
                  disabled={submittingPlanId === plan.id}
                >
                  {submittingPlanId === plan.id
                    ? "Redirecting..."
                    : "Subscribe Now"}
                </button>
              </div>
            ))}

          {isLoading && (
            <p>
              <BeatLoader />
            </p>
          )}
          {error && <p>Error loading plans: {error.message}</p>}
        </div>

        {visiblePlans[1] < plans.length && (
          <button
            onClick={handleNext}
            className="text-2xl p-2 border rounded-md hover:bg-gray-100 hidden lg:block"
          >
            &#8594;
          </button>
        )}
      </div> */}

      <div className="flex items-center justify-center gap-4">
        {visiblePlans[0] > 0 && (
          <button
            onClick={handlePrev}
            className="text-2xl p-2 border rounded-md hover:bg-gray-100 hidden lg:block"
          >
            &#8592;
          </button>
        )}

        <div className="flex flex-wrap flex-col items-center lg:flex-row lg:justify-center gap-8">
          {!isLoading &&
            !error &&
            plans
              .sort((a, b) => a.item.amount - b.item.amount) // Sort plans by price (low to high)
              .slice(visiblePlans[0], visiblePlans[1])
              .map((plan) => (
                <div
                  key={plan.id}
                  className="flex flex-col justify-between border border-black p-6 rounded-md min-h-92 min-w-[20rem]  text-center hover:shadow-lg "
                >
                  <h2 className="text-5xl font-bold mb-4">
                    {getCurrencySymbol(plan.item.currency)}
                    {plan.item.amount / 100}

                    {/* Check if the actual price is available */}
                    {plan?.notes?.actual_price && (
                      <div>
                        {/* Display actual price with strikeout */}
                        <span className="text-2xl font-medium text-gray-500 line-through ml-4">
                          {getCurrencySymbol(plan.item.currency)}
                          {plan?.notes.actual_price}
                        </span>

                        {/* Calculate and display discount percentage */}
                        <span className="text-lg font-medium text-green-500 ml-2">
                          (
                          {Math.round(
                            ((plan?.notes.actual_price -
                              plan.item.amount / 100) /
                              plan?.notes.actual_price) *
                              100
                          )}
                          % off)
                        </span>
                      </div>
                    )}
                  </h2>

                  <p className="text-gray-600 mb-4">
                    Billed {plan.interval} {plan.period}
                  </p>
                  <p className="text-lg mb-2 font-semibold">{plan.item.name}</p>

                  <p className="text-lg mb-2 flex flex-col items-start">
                    {plan.item.description
                      ? plan.item.description
                          .split("~")
                          .filter(Boolean)
                          .map((line, index) => (
                            <span
                              key={index}
                              className="flex items-center mb-2"
                            >
                              <FaCheck className="text-green-500 mr-3" />{" "}
                              {line.trim()}
                            </span>
                          ))
                      : "No description available"}
                  </p>

                  <button
                    className={`${
                      subscriptionDetails?.["Plan Name"] === plan?.item?.name
                        ? "bg-green-500 text-white px-6 py-2 rounded-md cursor-default"
                        : "bg-sky-400 text-white px-6 py-2 rounded-md hover:bg-sky-500"
                    } transition-colors duration-300`}
                    onClick={() => handleSubscribe(plan.id)}
                    disabled={
                      submittingPlanId === plan.id ||
                      subscriptionDetails?.["Plan Name"] === plan?.item?.name
                    }
                  >
                    {subscriptionDetails?.["Plan Name"] === plan?.item?.name
                      ? cancelSubscription
                        ? "Subscription Canceled"
                        : "Subscribed"
                      : submittingPlanId === plan.id
                      ? "Redirecting..."
                      : "Subscribe Now"}
                  </button>
                </div>
              ))}

          {isLoading && (
            <p>
              <BeatLoader />
            </p>
          )}
          {error && <p>Error loading plans: {error.message}</p>}
        </div>

        {visiblePlans[1] < plans.length && (
          <button
            onClick={handleNext}
            className="text-2xl p-2 border rounded-md hover:bg-gray-100 hidden lg:block"
          >
            &#8594;
          </button>
        )}
      </div>

      {/* Footer section */}
      <div className="flex flex-col md:flex-row md:justify-evenly items-center mt-8 mb-5">
        <p className="text-lg font-semibold mb-4">Need a Custom Plan?</p>
        <button
          className="border border-black px-6 py-2 rounded-md hover:bg-gray-200"
          onClick={() => setIsQuoteModalOpen(true)}
        >
          Get a Quote
        </button>
      </div>
      <GetAQuoteModal
        isOpen={isQuoteModalOpen}
        closeModal={() => setIsQuoteModalOpen(false)}
      />
    </div>
  );
};

export default Plans;
