import React, { useState } from "react";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";

const DownloadModal = ({
  isDownloadModalOpen,
  closeDownloadModal,
  downloadModalContent,
}) => {
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [isLoadingXLSX, setIsLoadingXLSX] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      minHeight: "70vh",
      maxWidth: "90vw",
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "#F6F5F5",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const handleDownload = async (fileFormat) => {
    try {
      const id = downloadModalContent?.id;
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!id) {
        console.error("File ID is missing");
        return;
      }

      // Set the loading state based on the file format
      if (fileFormat === "csv") {
        setIsLoadingCSV(true);
      } else if (fileFormat === "xlsx") {
        setIsLoadingXLSX(true);
      }

      const url = `${process.env.REACT_APP_BACKEND_URL}/uploads/generate-file/?fileFormat=${fileFormat}&ids=${id}`;

      // Make the API request to get the file as a blob
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      // Create a link element to trigger the download
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      const filename = `${
        downloadModalContent?.file_name
          ? downloadModalContent.file_name
          : downloadModalContent?.file.split("/").pop() || "data"
      }.${fileFormat}`;

      link.href = downloadUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Reset the loading state
      if (fileFormat === "csv") {
        setIsLoadingCSV(false);
      } else if (fileFormat === "xlsx") {
        setIsLoadingXLSX(false);
      }
    } catch (error) {
      console.error("Failed to download the file", error);

      // Reset the loading state in case of an error
      if (fileFormat === "csv") {
        setIsLoadingCSV(false);
      } else if (fileFormat === "xlsx") {
        setIsLoadingXLSX(false);
      }
    }
  };

  const formatName = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  return (
    <div className="raleway-regular">
      <Modal
        isOpen={isDownloadModalOpen}
        onRequestClose={closeDownloadModal}
        contentLabel="Download Modal"
        style={customStyles}
      >
        <h2 className="raleway-regular">Download Item</h2>
        {downloadModalContent && (
          <div className="p-4 raleway-regular">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-wrap gap-5 justify-evenly">
                <p className="text-sm font-semibold">
                  Name:{" "}
                  <span className="font-normal">
                    {downloadModalContent?.file_name
                      ? downloadModalContent?.file_name
                      : downloadModalContent?.file.split("/").pop()}
                  </span>
                </p>
                <p className="text-sm font-semibold">
                  Type:{" "}
                  <span className="font-normal">
                    {formatName(downloadModalContent?.file_type)}
                  </span>
                </p>
                <p className="text-sm font-semibold">
                  Owner:{" "}
                  <span className="font-normal">
                    {downloadModalContent?.owner_details?.first_name +
                      " " +
                      downloadModalContent?.owner_details?.last_name}
                  </span>
                </p>
              </div>
              <div className="absolute bottom-20 left-0 min-w-full">
                <div className="flex flex-wrap justify-center items-center gap-5">
                  <button
                    onClick={() => handleDownload("csv")}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600"
                    disabled={isLoadingCSV || isLoadingXLSX}
                  >
                    {isLoadingCSV ? "Downloading..." : "Download CSV"}
                  </button>
                  <button
                    onClick={() => handleDownload("xlsx")}
                    className="px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600"
                    disabled={isLoadingCSV || isLoadingXLSX}
                  >
                    {isLoadingXLSX ? "Downloading..." : "Download XLSX"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <button onClick={closeDownloadModal} className="absolute top-4 right-4">
          <span className="rounded-full text-gray-500 font-bold">
            <RxCross2 size={20} />
          </span>
        </button>
      </Modal>
    </div>
  );
};

export default DownloadModal;
