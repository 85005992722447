import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import FileViewer from "./FileViewer";
import VisualizedData from "./VisualizedData";
import { RxCross2 } from "react-icons/rx";
import { PropagateLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-hot-toast";
import { AuthContext } from "../AuthContext";

const PreviewModal = ({
  isPreviewModalOpen,
  closePreviewModal,
  previewModalContent,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      height: "96vh",
      maxWidth: "96vw",
      padding: "10px",
      borderRadius: "10px",
      backgroundColor: "#f3f4f6",
      position: "relative",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const [responseData, setResponseData] = useState({});

  const [activeTab, setActiveTab] = useState("visualizedData");

  const [isLoading, setIsLoading] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);

  const [isEditingName, setIsEditingName] = useState(false);
  const [editedBaseName, setEditedBaseName] = useState("");
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [updatedName, setUpdatedName] = useState(null);

  const { fetchData, userData } = useContext(AuthContext);

  const [isScrolled, setIsScrolled] = useState(true);

  // Ref for the scrollable container in the modal
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the modal container is scrolled
      if (scrollContainerRef.current) {
        setIsScrolled(scrollContainerRef.current.scrollTop > 0);
        console.log("hello");
      }
    };

    // Attach scroll event listener to the modal container
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const splitNameAndExtension = (fullName) => {
    const lastDotIndex = fullName.lastIndexOf(".");
    if (lastDotIndex === -1) {
      return { baseName: fullName, extension: "" };
    }
    return {
      baseName: fullName.substring(0, lastDotIndex),
      extension: fullName.substring(lastDotIndex),
    };
  };
  // console.log(isScrolled);

  useEffect(() => {
    if (!isPreviewModalOpen) {
      setResponseData({});
      setIsEditingName(false);
      setUpdatedName(null);
    } else if (previewModalContent?.information) {
      setResponseData(previewModalContent.information);
      const { baseName } = splitNameAndExtension(
        previewModalContent.name || ""
      );
      setEditedBaseName(baseName);
    }
  }, [isPreviewModalOpen, previewModalContent]);

  useEffect(() => {
    const getFileDetails = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("auth"))?.access;
        if (!token) {
          toast.error("Access token not found. Please log in.");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/uploads/files/${previewModalContent?.id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data) {
          if (response?.data?.status === "EXTRACTING") {
            setIsExtracting(true);
            setIsLoading(true);
          } else {
            setResponseData(response?.data?.information);
            setIsExtracting(false);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Only hit the API if isPreviewModalOpen is true
    if (isPreviewModalOpen) {
      getFileDetails();
    }
  }, [isPreviewModalOpen, previewModalContent?.id]);

  const handleNameClick = () => {
    setIsEditingName(true);
  };

  const handleNameChange = (e) => {
    setEditedBaseName(e.target.value);
  };

  const handleNameBlur = () => {
    handleNameUpdate();
  };

  const handleNameKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNameUpdate();
    } else if (e.key === "Escape") {
      const { baseName } = splitNameAndExtension(
        previewModalContent.name || ""
      );
      setEditedBaseName(baseName);
      setIsEditingName(false);
    }
  };

  const handleNameUpdate = async () => {
    if (isUpdatingName) return;

    const originalName = previewModalContent.name;
    const { extension } = splitNameAndExtension(originalName || "");
    const newName = editedBaseName.trim() + extension;

    // Validation: Name should not be empty
    if (editedBaseName.trim() === "") {
      toast.error("Name cannot be empty.");
      setEditedBaseName(splitNameAndExtension(originalName || "").baseName);
      setIsEditingName(false);
      return;
    }

    // If the name hasn't changed, exit editing mode
    if (newName === originalName) {
      setIsEditingName(false);
      return;
    }

    try {
      setIsUpdatingName(true);
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        toast.error("Access token not found. Please log in.");
        setIsUpdatingName(false);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/files/${previewModalContent.id}/update/`,
        { file_name: newName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Name updated successfully");
        setIsEditingName(false);
        setUpdatedName(response?.data?.file_name);
        fetchData();
      } else {
        toast.error("Failed to update name.");
        setEditedBaseName(splitNameAndExtension(originalName || "").baseName);
        setIsEditingName(false);
      }
    } catch (error) {
      toast.error("Error updating name. Please try again.");
      setEditedBaseName(
        splitNameAndExtension(previewModalContent.name || "").baseName
      );
      setIsEditingName(false);
    } finally {
      setIsUpdatingName(false);
    }
  };

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

    socket.onopen = () => {
      console.log("Connected to WebSocket");
    };

    socket.onerror = (error) => {
      console.error("WebSocket Error: ", error);
    };

    socket.onclose = (event) => {
      console.log("Connection closed: ", event);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.file_id === previewModalContent?.id) {
        if (data?.status === "EXTRACTING") {
          setIsExtracting(true);
          setIsLoading(true);
        } else if (data?.status === "EXTRACTED") {
          setResponseData(data?.info);
          setIsExtracting(false);
          setIsLoading(false);
          userData();
        } else {
          setIsExtracting(false);
          setIsLoading(false);
        }
      }
    };

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, [previewModalContent]);

  return (
    <Modal
      isOpen={isPreviewModalOpen}
      onRequestClose={closePreviewModal}
      contentLabel="Preview Modal"
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Close Button */}
      <button
        onClick={closePreviewModal}
        className="fixed top-1 right-2 text-gray-500 hover:text-red-700 focus:outline-none"
      >
        <RxCross2 size={24} />
      </button>

      {/* Modal Content */}
      {previewModalContent && (
        <div className="p-2 py-0.5 flex flex-col">
          <div className="p-2 flex flex-col md:flex-row md:gap-4">
            {/* File Viewer Section */}
            <div className="basis-2/5 flex-grow md:min-h-[60vh] min-h-[50vh] flex justify-start items-start">
              <FileViewer
                fileLink={previewModalContent.fileLink}
                fileType={previewModalContent.format}
                setResponseData={setResponseData}
                isExtracting={isExtracting}
                setIsExtracting={setIsExtracting}
                setIsLoading={setIsLoading}
                previewModalContent={previewModalContent}
                isScrolled={isScrolled}
              />
            </div>

            {/* Information and Editable Name Section */}
            <div className="basis-3/5 flex-grow min-h-[35vh] p-1 md:mb-1 mb-12">
              {/* Header with Editable Name */}
              <div
                className="flex justify-center items-center bg-white p-2 rounded-t-lg shadow-sm sticky top-2"
                style={{
                  borderWidth: "1px",
                }}
              >
                <div className="flex items-center">
                  {isEditingName ? (
                    <div className="flex items-center">
                      {/* Editable Base Name Input */}
                      <input
                        type="text"
                        value={editedBaseName}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        onKeyDown={handleNameKeyDown}
                        className="border rounded p-1 text-black focus:outline-none focus:ring focus:ring-blue-200"
                        autoFocus
                      />
                      {/* Static Extension */}
                      <span className="ml-2 text-gray-600">
                        {
                          splitNameAndExtension(previewModalContent.name || "")
                            .extension
                        }
                      </span>
                      {/* Loading Spinner During Update */}
                      {isUpdatingName && (
                        <PropagateLoader
                          color="#4A90E2"
                          size={10}
                          className="ml-2"
                        />
                      )}
                    </div>
                  ) : (
                    <span
                      className="text-black mr-2 cursor-pointer raleway-regular"
                      onClick={handleNameClick}
                      title="Click to edit name"
                    >
                      {/* Display Base Name and Extension */}
                      {
                        splitNameAndExtension(
                          updatedName
                            ? updatedName
                            : previewModalContent.name || ""
                        ).baseName
                      }
                      {
                        splitNameAndExtension(
                          updatedName
                            ? updatedName
                            : previewModalContent.name || ""
                        ).extension
                      }
                    </span>
                  )}
                </div>
              </div>

              {/* Tab Content */}
              <div>
                <div className="mt-1">
                  {activeTab === "visualizedData" && (
                    <>
                      {isLoading ? (
                        <div className="text-center mt-20 bg-slate-300">
                          <PropagateLoader color="#4A90E2" />
                        </div>
                      ) : responseData &&
                        Object.keys(responseData)?.length > 0 ? (
                        <VisualizedData
                          responseData={responseData}
                          previewModalContent={previewModalContent}
                        />
                      ) : (
                        <div className="p-2 min-h-[10rem] flex justify-center items-center">
                          No data available
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PreviewModal;
